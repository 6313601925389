<template>
  <UiEmptyState
    :image-src="require('@/assets/img/not_found_error.svg')"
    :title-text="$t('Sorry, we can’t find a matching order')"
  >
    <template #description>
      <p>
        {{
          $t(
            'Please, check if your postal code and your Track&Trace or Order Number are the same as in the emails you received. If the issue persists, contact the shop.'
          )
        }}
      </p>
    </template>
    <template #actions>
      <UiButton
        mode="primary"
        class="main-button"
      >
        <router-link
          class="main-button__btn"
          :style="brandStyle"
          :to="{ name: 'home' }"
        >{{ $t('Try again') }}</router-link>
      </UiButton>
      <UiButton
        v-if="supportUrl"
        mode="secondary"
      >
        <a
          class="main-button__btn secondary-button__btn"
          :href="supportUrl"
        >{{ $t('Support') }}</a>
      </UiButton>
      <UiButton
        v-else-if="brandWebsiteUrl"
        mode="secondary"
      >
        <a
          class="main-button__btn secondary-button__btn"
          :href="brandWebsiteUrl"
        >{{ $t('Support') }}</a>
      </UiButton>
    </template>
  </UiEmptyState>
</template>
<script>
import { brandColourMixin, allUrls } from '@/components/mixins'
import { UiButton, UiEmptyState } from '@sendcloud/ui-library'

export default {
  name: 'PostalCodeError',
  components: {
    UiEmptyState,
    UiButton,
  },
  mixins: [brandColourMixin, allUrls],
}
</script>
